export const usdAnyWhereMasterCodes: Dictionary<number> = {
'AX': 12793,
'AO': 12794,
'AW': 12795,
'AZ': 12796,
'BD': 12797,
'BZ': 12798,
'BO': 12799,
'VG': 12800,
'BF': 12801,
'CA': 12802,
'TD': 12803,
'CO': 12804,
'CR': 12805,
'CW': 12806,
'DK': 12807,
'DO': 12808,
'SV': 12809,
'ET': 12810,
'FR': 12811,
'GM': 12812,
'GH': 12813,
'GD': 12814,
'GN': 12815,
'HT': 12816,
'HU': 12817,
'ID': 12818,
'IL': 12819,
'JP': 12820,
'KZ': 12821,
'KG': 12822,
'LS': 12823,
'LT': 12824,
'DZ': 12825,
'AR': 12826,
'AU': 12827,
'BS': 12828,
'BB': 12829,
'BJ': 12830,
'EE': 12831,
'BW': 12832,
'BN': 12833,
'FK': 12834,
'KH': 12835,
'CV': 12836,
'CL': 12837,
'KM': 12838,
'CI': 12839,
'CY': 12840,
'DJ': 12841,
'EC': 12842,
'GF': 12843,
'GE': 12844,
'GI': 12845,
'GT': 12846,
'GW': 12847,
'HN': 12848,
'IS': 12849,
'IE': 12850,
'IT': 12851,
'JE': 12852,
'KE': 12853,
'LA': 12854,
'LR': 12855,
'LU': 12856,
'AD': 12857,
'AM': 12858,
'AT': 12859,
'CM': 12860,
'KY': 12861,
'BH': 12862,
'BE': 12863,
'BT': 12864,
'BR': 12865,
'BG': 12866,
'CN': 12867,
'CG': 12868,
'HR': 12869,
'CZ': 12870,
'DM': 12871,
'EG': 12872,
'SZ': 12873,
'FI': 12874,
'GA': 12875,
'DE': 12876,
'GR': 12877,
'GG': 12878,
'GY': 12879,
'HK': 12880,
'IN': 12881,
'IM': 12882,
'JM': 12883,
'JO': 12884,
'KW': 12885,
'LV': 12886,
'LI': 12887,
'MO': 12888,
'MG': 12889,
'MV': 12890,
'MR': 12891,
'MD': 12892,
'MN': 12893,
'NA': 12894,
'NZ': 12895,
'MP': 12896,
'PK': 12897,
'PY': 12898,
'PL': 12899,
'RO': 12900,
'LC': 12901,
'VC': 12902,
'SA': 12903,
'SL': 12904,
'SI': 12905,
'KR': 12906,
'SR': 12907,
'TW': 12908,
'TH': 12909,
'TT': 12910,
'TM': 12911,
'AE': 12912,
'UZ': 12913,
'ZM': 12914,
'MW': 12915,
'ML': 12916,
'MU': 12917,
'MA': 12918,
'YT': 12919,
'NP': 12920,
'NE': 12921,
'NO': 12922,
'PA': 12923,
'PE': 12924,
'PT': 12925,
'RW': 12926,
'MF': 12927,
'SM': 12928,
'SN': 12929,
'SG': 12930,
'SB': 12931,
'ES': 12932,
'SE': 12933,
'TJ': 12934,
'TL': 12935,
'TN': 12936,
'UG': 12937,
'GB': 12938,
'VU': 12939,
'MY': 12940,
'MT': 12941,
'MX': 12942,
'MC': 12943,
'MZ': 12944,
'NL': 12945,
'NG': 12946,
'OM': 12947,
'PG': 12948,
'PH': 12949,
'QA': 12950,
'BL': 12951,
'PM': 12952,
'ST': 12953,
'SC': 12954,
'SK': 12955,
'ZA': 12956,
'LK': 12957,
'CH': 12958,
'TZ': 12959,
'TG': 12960,
'TR': 12961,
'UA': 12962,
'UY': 12963,
'VN': 12964,
}